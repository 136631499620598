<template>
  <div>
    <center>
      <br v-if="isMobile"><br v-if="isMobile">
     <b-col cols="12" md="11" lg="7" xl="7">
        <b-row class="justify-content-center">
          <b-col cols="12" md="7" v-if="!enviar"  :class="!isMobile ? 'text-left' : ''">
              <p :style="isMobile ? 'color:#0c273a; font-size:28px;' : 'color:#0c273a; font-size:29px;'" >Queremos ayudarte, <br v-if="isMobile"> selecciona una opción:</p>
          </b-col>
            <!-- <b-col cols="11" md="5" v-if="!enviar">
                 <p @click="problema = 1" :class="isMobile ? 'ml-5 mt-2' : 'ml-5 mt-5'" :style="problema == 1 ? 'color:#187abe; cursor: pointer; font-size: 25px;   text-decoration: underline;' : 'color:#187abe; cursor: pointer; font-size: 25px;'">
                    ¿Problemas con tus cuotas?
                 </p>
            </b-col> -->
            <b-col cols="11" md="8" v-if="!enviar" :class="!isMobile ? 'text-left' : ''">
             <b-form-group   v-slot="{ ariaDescribedby }">
                <b-form-radio v-model="problema" :aria-describedby="ariaDescribedby" name="some-radios" value="1"  size="lg"  :class="isMobile ? '' : 'ml-4'"
                  :style="isMobile ? 'color:#0c273a; font-size:19px;  margin-left:-40px;' : 'color:#0c273a; font-size:20px;'">&nbsp; Problemas con tus cuotas</b-form-radio>
                <b-form-radio :class="isMobile ? 'mt-4' : 'mt-4 ml-4'" v-model="problema" :aria-describedby="ariaDescribedby" name="some-radios" value="2"  size="lg"  
                  :style="isMobile ? 'color:#0c273a; font-size:19px;  margin-left:-40px;' : 'color:#0c273a; font-size:20px;'">&nbsp; Problemas con plataforma</b-form-radio>
              </b-form-group>
            </b-col>
            <b-col cols="11" md="7">
              <template v-if="!enviar">
                    <div class="text-center">
                        <p class="text-danger" v-if="errors.includes( 'tipo' )"><b>Seleccione el problema</b></p>
                    </div>
                    <br>
                    <b-form  aria-autocomplete="false">
                      <b-form-input v-model="nombre" placeholder="Nombre completo"   type="text"  class="mt-3" />
                      <div class="text-center">
                        <p class="text-danger" v-if="errors.includes( 'nombre' )"><b>Ingrese nombre completo.</b></p>
                      </div>
                      <b-form-input v-model="email" placeholder="Correo electronico"   type="text"  class="mt-3" />
                      <div class="text-center">
                        <p class="text-danger" v-if="errors.includes( 'email' )"><b>Ingresa email.</b></p>
                      </div>
                      <b-form-textarea
                        id="textarea"
                        v-model="comentario"
                        placeholder="Descripción, cuéntanos qué es lo que sucede"
                        rows="4"
                        max-rows="6"
                        class="mt-3"
                      ></b-form-textarea>
                      <div class="text-center">
                        <p class="text-danger" v-if="errors.includes( 'comentario' )"><b>Ingresa comentario.</b></p>
                      </div>
                      <img src="elementos/enviar.png" class="rounded mt-5" alt="Image" :style="isMobile ? 'width:50%;' : 'width:35%; cursor:pointer;'"  @click="contacto()" > 
                        <b-spinner v-if="loader" small></b-spinner>  
                      <!-- <v-btn  color="#5cb617"  class="mt-4" block dark style="text-transform:capitalize;" type="submit">
                        Enviar <b-spinner v-if="loader" small></b-spinner> 
                      </v-btn> -->
                    </b-form>
                </template>
                <template v-else>
                  <b-row class="justify-content-center">
                    <b-col cols="11" md="9">
                      <br>
                      <b-card >
                      <br v-if="!isMobile">
                      <b-card-text class="text-center">
                        <center>
                          <img src="elementos/check.png" class="rounded" alt="Image" :style="isMobile ? 'width:30%;' : 'width:20%;'">
                        </center>
                          <p class="text-white mt-3" :style="isMobile ? 'font-size:37px;' : 'font-size:40px;'">
                                <b style="color:#5cb617;">Listo</b> <br>
                                
                          </p>  
                          <span :style="isMobile ? 'font-size:15px; color:#000;' : 'font-size:20px; color:#000;'">Tu mensaje se ha enviado exitosamente</span><br><br>  
                          <v-btn  color="#5cb617"  class="mt-4"  dark style="text-transform:capitalize; width: 30%;" @click="regresaCont()">
                           OK
                          </v-btn>
                      </b-card-text>
                      <br v-if="!isMobile"> 
                      </b-card>
                    </b-col>
                  </b-row>
                </template>
            </b-col>
        </b-row>
     </b-col>
    </center>
    <br><br>
  </div>
</template>

<script>
// import Swal from 'sweetalert2'
export default {
  name: 'contacto',
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      nombre:"",
      email:"",
      comentario:"",
      problema:0,
      errors:[],
      enviar:false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
  },
  methods:{
    contacto() { 
        this.errors = [];
        let model = this.prepareModel()
        
        if(this.problema == 0){
            this.errors.push('tipo');     
        }else if (!this.nombre){
            this.errors.push('nombre');       
        }else if(!this.email){
            this.errors.push('email');
        }else if(!this.comentario){
            this.errors.push('comentario');
        }else{
            this.loader = true;
            let request = this.$api.post("contacto/enviar", model);
            request.then(
                response => {   
                    console.log(response)      
                    this.loader = false;  
                    this.problema = 0;
                    this.comentario = ""; 
                    this.enviar = true; 
                    // Swal.fire(
                    // "Listo",
                    // "Tu mensaje se ha enviado exitosamente.",
                    // 'success'
                    // )           
                },
                error => {
                    this.loader = false;
                    console.log(error);
                }
            )
        }
    },
    prepareModel() {
        let model = new FormData();
        model.append("nombre", this.nombre)
        model.append("email", this.email)
        model.append("comentario", this.comentario)
        model.append("problema", this.problema)
        model.append("perfil", this.user.perfil)
        model.append("canal", this.user.canal)
        model.append("idpdv", this.user.id_pdv)
        return model;
    },
    regresaCont(){
       this.enviar =false;
    }
  },
  mounted(){
    this.nombre = this.user.nombre;
    this.email = this.user.email;
    if (this.isMobile)
    {
      this.$bus.$emit("cambi_fo7", true)
    } 
  }
}
</script>
<style>
#sombra{
-webkit-box-shadow: 3px -22px 166px -44px rgba(24,122,190,0.91);
-moz-box-shadow: 3px -22px 166px -44px rgba(24,122,190,0.91);
box-shadow: 3px -22px 166px -44px rgba(24,122,190,0.91);
}
</style>